import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { ConfirmedValidator } from './confirmed.validators';
import { UserProfileService } from '../../../core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})

/**
 * Reset-password component
 */
export class PasswordresetComponent implements OnInit, AfterViewInit {

  resetForm: FormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;
  uid;
  table:any;
  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, 
    private router: Router, private userProfileService:UserProfileService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params
    .subscribe( ({ id }) =>{
      this.searchToken( id );
    });

    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required,Validators.minLength(8),Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      password2: ['', [Validators.required]],
    },{
      validator: ConfirmedValidator('password', 'password2')
  });


 
  }

  ngAfterViewInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;

    if(this.resetForm.invalid){
      return;
    }

    let id_de  =this.uid;
    let body = {
      ...this.resetForm.value,
      id:id_de,
      table:this.table
    }

    this.userProfileService.changePassword(body).subscribe((resp:any) =>{

      Swal.fire('Success',resp.msg,'success');

      this.router.navigateByUrl('/account/login');


  },err=>{
    // Notification.fnc('Error',err.error.message,'error');
      Swal.fire('Error',err.error.message,'error');
    this.router.navigateByUrl('/account/login');
  })
   
  }

  searchToken(id:string){
    if(!id){
      this.router.navigateByUrl('/account/nopagefound')
     return;
    }

    this.userProfileService.ValidToken(id).subscribe((resp:any) =>{

     if(resp.ok){
        console.log(resp.table);
      this.uid = resp.uid;
      this.table = resp.table;

      

     }else{
       Swal.fire('Error',resp.msg,'error');
       this.router.navigateByUrl('/account/nopagefound')
     }
    },(err)=>{

     Swal.fire('Error',err.error.message,'error');
     this.router.navigateByUrl('/account/nopagefound')

    })
 }
}
